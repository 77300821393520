<template>
    <CompTable class="information-read" ref="comp_table" title="信息阅读量管理" :searchData="searchData" :columns="columns" :table-api="tableApi" :dataBefore="onDataBefore" @on-reset="onReset">
        <template #search="evt">
            <RadioGroup v-model="evt.search.tipsCode" type="button" v-if="tips">
                <Radio v-for="(item, idx) in tips" :key="idx" :label="item.dictKey">{{ item.dictValue }}</Radio>
            </RadioGroup>
            <Select v-model="evt.search.orgCode" filterable>
                <Option v-for="item in orgList" :key="item.orgCode" :value="item.orgCode">{{ item.orgName }}</Option>
            </Select>
            <DatePicker v-model="evt.search.startMonth" type="month" placement="bottom-end" placeholder="起始月份" style="width: 150px;min-width: 150px" />
            <DatePicker v-model="evt.search.endMonth" type="month" placement="bottom-end" placeholder="截止月份" style="width: 150px;min-width: 150px" />
        </template>
    </CompTable>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

export default {
    components: { CompTable },

    data() {
        return {
            orgCode: null,
            tableApi: null,
            orgList: [],
            searchData: {},
            columns: [
                {
                    title: "信息名称",
                    key: "title",
                },
                {
                    title: "发布范围",
                    key: "scopeName",
                },
                {
                    title: "发布时效",
                    width: 250,
                    render: (h, p) => p.row.startTime + " - " + p.row.endTime,
                },
                {
                    title: "信息编码",
                    key: "code",
                },
                {
                    title: "阅读量 ( 人次 )",
                    key: "viewCount",
                    width: 150,
                },
                {
                    title: "阅读率 ( % )",
                    width: 150,
                    render: (h, p) => (p.row.viewUserCount || 0) * 100 + "%",
                },
                {
                    title: "增加量",
                    width: 200,
                    render: (h, p) => {
                        // 默认值
                        p.row.increase = 50

                        return h("input", {
                            attrs: { type: "number", value: p.row.increase, placeholder: "输入增加量" },
                            on: {
                                input(e) {
                                    p.row.increase = e.target.value
                                },
                            },
                            style: {
                                border: "1px solid #ccc",
                                width: "120px",
                                padding: "3px",
                                textAlign: "center",
                                borderRadius: "4px",
                            },
                        })
                    },
                },
                // 操作
                {
                    title: "操作",
                    width: 150,
                    fixed: "right",
                    render: (h, p) => {
                        let isRight = window.sessionStorage
                            .getItem("roleCodes")
                            ?.split(",")
                            ?.includes("INFORMATION_READ:SUBMIT")

                        return isRight
                            ? h(
                                  "Button",
                                  {
                                      hasPermi: ["INFORMATION_READ:SUBMIT"],
                                      props: {
                                          type: "info",
                                      },
                                      style: "margin:0 3px",
                                      nativeOn: {
                                          click: () => {
                                              if (!p.row.increase) {
                                                  return this.$Message.error("请输入阅读增加量")
                                              }

                                              Request.get("/gateway/syinfopublish/api/pc/information/v2/addUserRead", {
                                                  orgCode: this.orgCode,
                                                  count: p.row.increase,
                                                  informationId: p.row.id,
                                              }).then(res => {
                                                  this.$Message.success("提交成功")
                                                  // 刷新表格
                                                  setTimeout(() => {
                                                      this.$refs.comp_table.refresh()
                                                  }, 1000)
                                              })
                                          },
                                      },
                                  },
                                  "提交"
                              )
                            : null
                    },
                },
            ],

            tips: null,
        }
    },

    created() {
        this.getOrgOnlineList()
        this.getDicType("INFORMATION_TIPS")
    },

    methods: {
        // 获取字典
        getDicType(code) {
            this.$get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: code,
                appCode: "",
            }).then(res => {
                if (res.code != 200 || !res.dataList?.length) {
                    return this.$Message.error("获取字典数据失败！")
                }

                this.tips = res.dataList
            })
        },

        // 获取下拉社区选项数据
        getOrgOnlineList() {
            Request.get("/orgzz/pc/organization/searchOrgOnlineList?orgOnlineType=1").then(res => {
                if (!res?.length) return

                this.orgList = res

                // 默认第一个机构
                this.$set(this.searchData, "orgCode", (this.orgCode = res[0].orgCode))
                // 获取用户信息
                const userinfo = JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
                // 表格接口
                this.tableApi = `/gateway/syinfopublish/api/pc/information/v2/searchTipsActionPage?custGlobalId=${userinfo.custGlobalId}&orgCode=${res[0].orgCode}`
            })
        },

        onDataBefore(data) {
            if (data.startMonth) {
                data.startMonth = this.$core.formatDate(new Date(data.startMonth), "yyyy-MM")
            }

            if (data.endMonth) {
                data.endMonth = this.$core.formatDate(new Date(data.endMonth), "yyyy-MM")
            }

            this.orgCode = data.orgCode

            return data
        },

        onReset() {
            this.orgCode = this.orgList?.[0]?.orgCode
            this.$refs.LiefengCascaderCity.changeClear()
        },
    },
}
</script>
<style lang="less">
.information-read {
    .ivu-cascader {
        margin-right: 0 !important;
    }
}
</style>
